.qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
}

.qr-reader {
  width: 430px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.qr-frame {
  display: none;
}

@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}

.button-layer {
  margin: 0 30px;
}

.button-container {
  position: fixed;
  bottom: 101px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
