.container {
  border-radius: 20px;
  margin: 20px;
  padding: 15px 20px;
}

.mainInfo {
  display: flex;
  align-items: baseline;
}

.numberStation {
  font-size: calc(1.4rem + 0.7vw);
  font-family: 'OpenSansSemiBold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 4px !important;
}

.nameStation {
  font-size: calc(1.3rem + 0.4vw);
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 0 !important;
}

.status {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.readyCharge {
  font-size: 25px;
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0;
}

.online {
  border-radius: 25px;
  width: 20px;
  height: 20px;
  background-color: #8f00ff;
  margin-left: 5px;
}

.offline {
  border-radius: 25px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  margin-left: 5px;
}

.linkToStation {
  text-decoration: none;
  min-width: 400px !important;
}

.delayedStartTime {
  font-size: 15px;
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0;
}

@media (max-width: 280px) {
  .status {
    flex-wrap: wrap;
  }

  .container {
    padding: 10px 15px;
  }
  .delayedStartTime {
    font-size: 10px;
  }
}
