:root {
  --font-family: "Alumni Sans", sans-serif;
  --second-family: "Montserrat", sans-serif;
}

.toggle-icon {
  display: none;
}

.btnLang {
  color: #cd55e0 !important;
  background-color: transparent !important;
  border: none;
  text-underline-offset: 8px;
}



.btnLang.active {
  text-decoration: underline;
  color: white !important;
}

.nav-conteiner {
  margin: -6px 0 0 20px;
}

.nav-link {
  font-family: var(--font-family);
  font-weight: 500;
  letter-spacing: 1.1px;
  color: #cd55e0 !important;
  text-underline-offset: 8px;
  font-size: 22px;

  :hover {
    text-decoration: underline;
  }
}

.nav-link:hover {
  text-decoration: underline !important;
  color: white !important;
}

#layoutNavBarChangeLangNavLink {
  display: flex;
}

#layoutNavBarChangeLangNavLink:hover {
    text-decoration: none !important;
}

.btnLang {
  font-weight: 250;
  font-size: 18px;
  text-underline-offset: 3px;
}

.leng-icon {
  margin: 0 4px;
}

@media (max-width: 991px) {
  .nav-conteiner {
    display: flex;
    width: 100%;
    padding-bottom: 12px;
    margin: auto;
    justify-content: center;
  }

  .nav-overlay {
    display: none;
    background: #161616;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1020;
    transition: display 4s allow-discrete, opacity 300ms;
  }

  .navbar-collapse {
    background: linear-gradient(180deg, rgba(235, 147, 247, 0.15) 0%, rgba(143, 64, 153, 0.15) 100%), #202020;
    z-index: -15;
    border-radius: 0px 0px 15px 15px;
  }

  .collapse:not(.show) {
    visibility: hidden !important;
    display: block !important;
  }

  .collapsed-menu {
    padding-bottom: 32px;
  }

  .collapsed-menu-content {
    font-weight: 400;
    letter-spacing: 1.2px;
    font-family: var(--font-family);
    padding-top: 14px;
  }

  .nav-link {
    font-family: var(--font-family);
    font-weight: 500;
    letter-spacing: 1.1px;
    color: #cd55e0 !important;
    text-underline-offset: 8px;
    font-size: 42px;

    :hover {
      text-decoration: underline;
    }
  }

  .nav-link:hover {
    text-decoration: underline !important;
    color: white !important;
  }

  .btnLang {
    font-weight: 350;
    font-size: 36px;
    color: #cd55e0 !important;
    background-color: transparent !important;
    border: none;
    text-underline-offset: 8px;
  }

  .toggle-icon {
    display: block;

    width: 60px;
    height: 60px;

    background-image: url('../assets/burgerMenu.svg');
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: -4px 0px;
    background-color: transparent;
    transition: background-image 200ms;

    border: none;
  }

  .open {
    background-image: url('../assets/cancel v4.svg');
  }

  .leng-icon {
    margin: 0 24px;
  }

  .balance-container {
    width: 25%;
  }
}

.balance-container {
  position: absolute;
  right: 0px;
  top: 15px;
  align-items: center;
  background: linear-gradient(#2e2e2e, #202020) padding-box, linear-gradient(-16deg, #c100b9, #6e00d4) border-box;
  border: 4px solid #0000;
  border-radius: 15px;
  display: flex;
  height: 60%;
  justify-content: center;
  margin: 0 20px 0 5px;
  width: 98px;
  .textBalance {
    letter-spacing: 0;
    margin: 0;
  }
}

.footerText {
  text-align: center;
  padding: 10px 12px 0;
  border-top: 1px solid #e6e6e6;
  font-family: 'OpenSansRegular', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: calc(0.6rem + 0.3vw);
  margin-bottom: 5px;
}

.footerLink {
  text-decoration: none;
}

.footerLink:hover {
  text-decoration: underline;
}

.navbar {
  z-index: 2;
}

.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.close-navbar-toggler.collapsed {
  z-index: -1;
}


@media (max-height: 800px) and (orientation: landscape) {
  .collapsed-menu {
    margin-right: 115px;
  }
  
  .footerText {
    text-align: center;
    padding: 5px 12px 0;
    border-top: 1px solid #e6e6e6;
    font-family: 'OpenSansRegular', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: calc(0.5rem + 0.3vw);
    margin-bottom: 2px;
  }
}



#layoutNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  max-height: 80px;
  padding: 8px 0px;
  opacity: 0.96;
}



.nav-glow {
  bottom: 0;
  background: linear-gradient(#2e2e2e, #202020) padding-box;
  box-shadow: 0 12px 15.94px 0 #702daa, 0 -12px 39.86px 0 #f234eecc;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -10;
}

#layoutLogoLink {
  margin-top: 4px;
}


/* @media (max-width: 768px) {

}


@media (min-width: 769px) {

} */