.dotsBox {
  display: flex;
  justify-content: space-around;
  margin: 0 auto 5px;
}

.dotItem {
  border-radius: 25px;
  background-color: #8f00ff;
}

.dotItemDefault {
  background-color: #d2b8d4;
}

.dotItemCharged {
  background-color: #8f00ff;
}

.dotItemActive {
  /* background-color: #d2b8d4; */
  animation: dotItemActive 1.5s infinite;
}

@keyframes dotItemActive {
  0% {
    background-color: #d2b8d4;
  }
  50% {
    background-color: #8f00ff;
  }
  100% {
    background-color: #d2b8d4;
  }
}
