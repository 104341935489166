.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 1000;
}

.modalActive {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: all;
  transition: 0.5s;
  z-index: 1000;
}

.modalContent {
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
  transform: scale(0.5);
  transition: 0.4s all;
}

.modalContentActive {
  padding: 20px;
  margin: 10px;
  border-radius: 12px;
  background-color: #fff;
  transform: scale(1);
  transition: 0.4s all;
}
