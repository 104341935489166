.loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #e2e2e2;
  border-radius: 50%;
  border-top-color: #8f00ff;
  animation: spin 1s ease-in-out infinite;
  margin-top: 37px;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loadingChar {
  margin: 4.5px 0;
}
