.logoutBtn {
  background-color: #cd9df2;
  padding: 5px 10px;
  border-radius: 16px;
  color: #333;
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none !important;
}

.logoutBtn:hover {
  color: inherit;
}

.infoBox {
  position: fixed;
  top: 120px;
  right: 15px;
  display: flex;
  align-items: center;
  z-index: 99999;
}

.toggleStyle {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.toggleStyle::after {
  display: none !important;
}

.menuToggle {
  /* background-color: inherit !important; */
  padding: 10px !important;
  /* box-shadow: 0px 10px 17px -3px rgba(0,0,0,.075) !important;     */
}

.userText {
  color: inherit;
  margin: 0;
}
