.mainBtn {
  cursor: pointer;
  justify-content: center !important;
  background-color: #8f00ff;
  border-radius: 16px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 25px auto !important;
  max-width: 30rem;
  min-width: 17rem;
  /* height: 48px; */
  text-decoration: none !important;
  border: none;
  width: 100%;
}

.smallBtn {
  cursor: pointer;
  justify-content: center !important;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 16px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 10px auto !important;
  max-width: 7rem;
  min-width: 6rem;
   /*height: 48px;*/
  text-decoration: none !important;
  border: none;
  opacity: 0.63;
}

.mainBtnText {
  color: #fafafa;
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin-top: 1px;
  margin-bottom: 0 !important;
  text-align: center;
}

.buttonForCallingUs {
  margin: 0 15px 75px 15px;
}

@media (min-width: 435px) and (max-width: 470px) {
  .buttonForCallingUs {
    margin-bottom: 55px;
  }
}

@media (min-width: 1024px) {
  .buttonForCallingUs {
    margin-bottom: 55px;
  }
  .mainBtn {
    min-width: 30rem;
  }
}
