.mainBlock {
  transition-duration: 1s;
  text-align: center;
}

.kmCharged {
  font-family: 'OpenSansRegular', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: calc(1.5rem + 1.5vw);
}

.textTitle {
  font-family: 'OpenSansSemiBold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: calc(0.8rem + 1.4vw);
  padding: 0 5px 0 5px;
}

.textInfo {
  font-family: 'OpenSansRegular', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: calc(1rem + 1.6vw);
  padding: 0 5px 0 5px;
}

.voltTitle {
  font-size: calc(0.8rem + 1.1vw);
  margin-bottom: 0;
}

.stationText {
  font-size: calc(1.3rem + 0.4vw);
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  border-bottom: 1px solid #dbdbdb;
}

.station {
  align-items: baseline;
}

.stationNumber {
  font-size: calc(1.4rem + 0.7vw);
  font-family: 'OpenSansSemiBold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-left: 5px;
}

.offCont {
  display: none;
}

.finishTitle {
  font-family: 'OpenSansBold', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: calc(1.2rem + 1.5vw);
}

.finishText {
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: calc(1rem + 1.5vw);
  margin-bottom: 15px;
}

.modalBtn {
  font-size: calc(1.7rem + 1.6vw);
  padding: 0 15px;
  margin-bottom: 15px;
  background-color: #8f00ff;
  border-radius: 10px;
  display: inline-block;
}

.activeBtnVoltage {
  padding: 10px 0 10px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.fullInfoCont {
  padding-top: 10px;
  padding-bottom: 1px;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 100%;
}

.infoActive {
  transform: scale(1);
  transition: 1s all;
}

.info {
  transform: scale(0.5);
  transition: 1s all;
}

.pushable {
  background: #530092;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}

.front {
  display: block;
  padding: 0 10px 0 10px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #8f00ff;
  color: white;
  transform: translateY(-6px);
}

.pushable:active .front {
  transform: translateY(-2px);
}

.disableModalBtn {
  display: inline-block;
  justify-content: center;
}

.disableModalBtnText {
  font-size: calc(1.7rem + 1.6vw);
  margin-bottom: -5px;
  margin-top: -10px !important;
}

.chartWidth {
  max-width: 500px;
}

@media (max-height: 800px) and (orientation: landscape) {
  .textTitle {
    font-size: calc(0.9rem + 0.5vw);
  }
  .textInfo {
    font-size: calc(0.8rem + 0.4vw);
    margin-bottom: 0;
  }
  .voltTitle {
    font-size: calc(0.7rem + 0.4vw);
  }
  .fullInfoCont {
    width: 100%;
    margin: 0 auto 10px;
  }
  .chartWidth {
    max-width: 400px;
  }
  .stationText {
    font-size: calc(1rem + 0.4vw);
  }
  .stationNumber {
    font-size: calc(1.1rem + 0.7vw);
  }
}
