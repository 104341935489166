.containers-paddings {
    padding: 0 15px 0 15px;
}

.qr-button {
    position: fixed;
    left: 50%;
    top: 78lvh;
    transform: translateX(-50%);
    z-index: 999;
    background: linear-gradient(0deg, #C100B9 0%, #6e00d4 100%) border-box;
    border: 14px solid transparent;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 6px #9B2198FC;
}

.filter-svg {
    position: fixed;
    left: 75px;
    top: 120px;
    transform: translateX(-50%);
    z-index: 999;
}
