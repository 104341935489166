.container {
  margin-top: 120px;
  max-width: 1240px;
  margin: 0 auto;
}

.contractBox {
  margin-top: 15px;
}

.textContainer {
  padding: 15px 20px 50px 20px;
  border-radius: 10px;
}

.headerLinks {
  margin: 25px 0 25px 0;
}

.introductionText {
  margin: 50px 0 50px 0;
}

.title {
  font-family: 'OpenSansSemibold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: calc(0.9rem + 0.7vw);
  margin-top: 30px;
  padding: 0 20px;
}

.contentTitle {
  font-size: 20px;
  text-align: left;
  margin-bottom: 35px;
}

p {
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.offerLink {
  text-decoration: none;
  color: #8f00ff;
}

.offerLink:hover {
  color: #000;
}

.congrats220 {
  color: #8f00ff;
}

@media (max-width: 1340px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 1080px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0 20px;
  }

  .contentPage {
    margin-top: 80px;
  }
}

@media (max-height: 1280px) {
  .contentPage {
    margin-bottom: 20px;
  }
}

@media (max-height: 580px) {
  .contentPage {
    margin-top: -80px;
    margin-bottom: -80px;
  }
}
