.container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;

    user-select: none;
    text-decoration: none;

    border-radius: 20px;
    box-shadow: 2px 2px 2px 0 #1d1d1d, -2px -2px 2px 0 #575757;
    background: #2E2E2e;
  }

.invert {
  padding: 0.7px 1px 0.7px 1px;

  box-shadow: 2px 2px 4px 0 rgba(255, 255, 255, 0.25), -2px -2px 4px 0 rgba(0, 0, 0, 0.25), inset 0 4px 4px 0 rgba(0, 0, 0, 0.25), inset -2px -2px 4px 0 rgba(26, 26, 26, 0.25);
  background: #242424;
}

.active {
  background: 
    linear-gradient(#2E2E2E 0%, #2E2E2E 100%) padding-box,
    linear-gradient(-16deg, #C100B9 0%, #6e00d4 100%) border-box;
  border: 4px solid transparent;

  box-shadow: 
    -2px -2px 10px 0px #9310d6,
    2px 2px 10px 0px #c83dc5;

    animation: 2000ms ease-in-out infinite alternate breather;
}


@keyframes breather {
  0% {
    box-shadow: 
    -2px -2px 13px 0px #9310d6,
    2px 2px 13px 0px #c83dc5;
  }
  100% {
    box-shadow: 
    -2px -2px 15px 2px #9310d6,
    2px 2px 15px 2px #c83dc5;
  }
}