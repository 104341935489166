.dropDow {
  background-color: #8f00ff;
  border-radius: 16px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .color {
  color: #8f00ff;
} */
