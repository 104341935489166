.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: 'relative';
  width: 282px;
  height: 68px;

  border-radius: 20px;
  background: linear-gradient(-16deg, #6e00d4 0%, #C100B9 100%) padding-box,
    linear-gradient(-16deg, #C100B9 0%, #6e00d4 100%) border-box;
  backdrop-filter: blur(4.300000190734863px);
  box-shadow: 2px 2px 2px 0 #1d1d1d;  
  border: 4px solid transparent;

  color: #fff;
  font-weight: 600;
  user-select: none;
  text-wrap: nowrap;

  a, :hover {
    color: #fff;
    text-decoration: none;
    text-wrap: nowrap;
  }
}

.outline {
  border-radius: 12px;
  background: linear-gradient(#252525 0%, #252525 100%) padding-box,
    linear-gradient(-16deg, #C100B9 0%, #6e00d4 100%) border-box;
}
.outlineAlt {
  border-radius: 12px;
  background: linear-gradient(#2E2E2e 0%, #2E2E2e 100%) padding-box,
    linear-gradient(-16deg, #C100B9 0%, #6e00d4 100%) border-box;
  /* box-shadow: none;   */
}

.button:active {
  background: linear-gradient(-0deg, #8F40993C 0%, #EB93F73C 100%) padding-box,
    linear-gradient(-16deg, #C100B9 0%, #6e00d4 100%) border-box;
}