.calibrationCont {
  display: flex;
  flex-direction: column;
}

.calibrationTitle {
  font-family: 'OpenSansExtraBold', Arial, Helvetica, sans-serif;
  padding-bottom: 3px;
  margin-bottom: 8px;
  text-align: center;
  border-bottom: 1px solid rgb(206, 206, 206);
}

.calibrationText {
  font-family: 'OpenSansExtraBold', Arial, Helvetica, sans-serif;
  margin-bottom: 8px;
}

.btnSend {
  cursor: pointer;
  background-color: #8f00ff;
  border-radius: 16px;
  text-align: center;
  color: #fafafa;
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 17px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.repeat {
  padding-left: 15px;
  padding-right: 15px;
}

.calibrationSuccess {
  font-family: 'OpenSansExtraBold', Arial, Helvetica, sans-serif;
  padding-bottom: 3px;
  margin-bottom: 8px;
  text-align: center;
}

.drobdownBtn {
  background-color: #8f00ff !important;
  border: none !important;
  outline: none !important;
  position: relative;
  box-shadow: none !important;
}

.drobdownBtn::after {
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 40%;
  display: inline-block;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.selectBox {
  position: relative;
  height: 205px;
  width: 100%;
  overflow-y: scroll;
  text-align: center;
  border: 1px solid #8f00ff;
  border-radius: 10px;
}

.listKm {
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 0;
  padding: 2px;
  color: #2c2b2b;
}

.celected {
  background-color: #8f00ff;
  color: #fafafa;
}

.listKm:not(:last-child) {
  border-bottom: 1px solid #8f00ff;
}
