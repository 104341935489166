.numError {
  font-family: 'OpenSansRegular', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: calc(8rem + 3vw);
  margin: 0;
  /* color: #06935f; */
}

.title {
  font-family: 'OpenSansBold', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: calc(1.3rem + 0.8vw);
  margin: 0;
  text-align: center;
  /* color: #28464D; */
}

.body {
  font-family: 'OpenSansRegular', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: calc(0.7rem + 0.3vw);
  margin: 10px 0 0 0;
  text-align: center;
  /* color: #28464D; */
}

.btnCont {
  padding: 0 15px;
}
