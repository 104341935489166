.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.historyLink {
  color: #333; /* Default text color */
  font-weight: normal; /* Default font weight */
  text-decoration: underline; /* Add underline to text */
  font-style: italic; /* Italicize the text */
  transition: color 0.3s, font-weight 0.3s, font-style 0.3s; /* Smooth transition */
}

.historyLink:hover,
.historyLink:focus {
  color: #007bff; /* Change text color on hover/focus */
  font-weight: bold; /* Make text bold on hover/focus */
  text-decoration: none; /* Remove underline on hover/focus */
  font-style: normal; /* Revert italic style on hover/focus */
}

.historyLink:active {
  color: #004085; /* Darker text color on click */
}
