.title {
  font-size: calc(2rem + 1.5vw);
  font-family: 'OpenSansBold', Arial, Helvetica, sans-serif;
  font-weight: 700;
  /* color: #393939; */
  text-align: center;
  padding-right: 7px;
  padding-left: 7px;
  margin-bottom: 0;
}

.btnStart:hover {
  text-decoration: none;
  color: #fafafa;
}

.disableBtn {
  opacity: 0.3;
  pointer-events: none;
}

.btnStartContainer {
  padding: 0 15px;
}

.stationText {
  font-size: calc(1.3rem + 0.4vw);
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-bottom: 0;
  /* color: #393939; */
}

.stationNumber {
  font-size: calc(1.4rem + 0.7vw);
  font-family: 'OpenSansSemiBold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  /* color: #393939; */
}

.delayedStartWarning {
  font-size: calc(1.2rem + 0.8vw);
  font-family: 'OpenSansBold', Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-align: center;
}

.delayedStartTime {
  font-size: calc(1rem + 0.5vw);
  font-family: 'OpenSansSemiBold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
}

.waititngTitle {
  font-size: calc(1.2rem + 0.8vw);
  font-family: 'OpenSansSemiBold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}

.portInfo {
  font-size: 38px;
  letter-spacing: 2px;
}

@media (max-width: 720px) {
  .title {
    font-size: calc(1.5rem + 1.5vw);
  }
}

@media (max-width: 420px) {
  .title {
    font-size: calc(1.5rem + 1.3vw);
  }
  .portInfo {
    font-size: 8.5vw;
  }
}

@media (max-width: 330px) {
  .title {
    font-size: calc(1.2rem + 1.5vw);
  }

  .btn {
    font-size: calc(1rem + 2vw);
  }
  .delayedStartWarning {
    font-size: calc(0.8rem + 0.8vw);
  }
  .waititngTitle {
    font-size: calc(0.8rem + 0.8vw);
  }
}
