.map-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  .maplibregl-ctrl-top-right {
    right: 8px;
    top: 115px;
  }
}
