.station {
  align-items: baseline;
}

.stationText {
  font-size: calc(1.3rem + 0.4vw);
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  border-bottom: 1px solid #dbdbdb;
}

.stationNumber {
  font-size: calc(1.4rem + 0.7vw);
  font-family: 'OpenSansSemiBold', Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 0 !important;
}

.stationType {
  font-size: calc(0.9rem + 0.4vw);
  font-family: 'OpenSansMedium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin: 0;
  margin-left: 5px;
}
